var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      class: _vm.color
        ? ""
        : this.$vuetify.theme.dark
        ? "dark-theme"
        : "light-theme",
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        id: "Réteg_1",
        "data-name": "Réteg 1",
        viewBox: "0 0 600 165",
        fill: _vm.color,
      },
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d: "m140.15,28.79v8.52c50.02,14.75,43.56,86.29-8.27,91.85v-13.86c-38.54,3.92-51.94-48.23-16.54-63.47v-8.79c-42.59,14.18-36.3,75.36,8.27,80.59v13.82c68.08,2.95,82.1-91.46,16.54-108.67Zm-16.68,90.67h.14v.02s-.09-.01-.14-.02Zm16.68,12.51h-.04s.02,0,.04,0h0Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m80.88,82.5c-.08-24.51,19.16-44.7,42.73-46.67v13.86c38.54-3.92,51.94,48.23,16.54,63.47v8.79c42.59-14.18,36.3-75.36-8.27-80.59v-13.82c-68.12-2.86-82.12,91.36-16.54,108.67v-8.52c-19.84-5.45-34.46-23.64-34.46-45.18Zm34.46-49.47h0s0,0,0,0h0Z",
          },
        }),
      ]),
      _c("g", [
        _c("path", {
          attrs: {
            d: "m277.33,105.61c-3.47,0-6.72-.61-9.74-1.83-3.03-1.22-5.66-2.94-7.91-5.17-2.25-2.23-4.01-4.88-5.28-7.95-1.26-3.07-1.9-6.46-1.9-10.16s.63-7.02,1.9-10.09c1.27-3.07,3.03-5.73,5.28-7.98,2.25-2.25,4.89-3.99,7.91-5.21,3.03-1.22,6.27-1.83,9.74-1.83s6.64.61,9.64,1.83c3,1.22,5.63,2.96,7.88,5.21,2.25,2.25,4.01,4.91,5.27,7.98,1.27,3.07,1.9,6.44,1.9,10.09s-.63,7.09-1.9,10.16c-1.26,3.07-3.02,5.72-5.27,7.95-2.25,2.23-4.88,3.95-7.88,5.17-3,1.22-6.22,1.83-9.64,1.83Zm0-6.05c3.33,0,6.32-.77,8.97-2.32,2.65-1.55,4.75-3.75,6.29-6.61,1.55-2.86,2.32-6.24,2.32-10.13s-.77-7.33-2.32-10.16c-1.55-2.84-3.65-5.03-6.29-6.58-2.65-1.55-5.64-2.32-8.97-2.32s-6.4.77-9.07,2.32c-2.67,1.55-4.77,3.74-6.29,6.58s-2.29,6.23-2.29,10.16.77,7.27,2.32,10.13c1.55,2.86,3.65,5.06,6.29,6.61,2.65,1.55,5.66,2.32,9.04,2.32Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m309.83,69.94h6.05l.42,4.08c1.13-1.46,2.56-2.58,4.29-3.38,1.73-.8,3.66-1.2,5.77-1.2,2.72,0,5.07.57,7.07,1.72,1.99,1.15,3.54,2.85,4.64,5.1,1.1,2.25,1.65,5.06,1.65,8.44v20.4h-6.61v-19.56c0-3.28-.69-5.78-2.08-7.49-1.38-1.71-3.39-2.57-6.01-2.57s-4.83.88-6.33,2.64c-1.5,1.76-2.25,4.35-2.25,7.77v19.2h-6.61v-35.17Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m354,92.1c.28,1.31.86,2.53,1.72,3.66.87,1.12,2.05,2.04,3.55,2.74,1.5.71,3.35,1.06,5.55,1.06,3.28,0,5.71-.76,7.28-2.29s2.36-3.32,2.36-5.38c0-1.69-.48-3.08-1.44-4.19-.96-1.1-2.28-2.04-3.94-2.81s-3.58-1.46-5.73-2.07c-1.87-.57-3.72-1.16-5.52-1.8s-3.43-1.43-4.89-2.39c-1.45-.96-2.6-2.2-3.45-3.73-.84-1.52-1.26-3.43-1.26-5.73,0-2.86.68-5.32,2.04-7.38s3.27-3.65,5.73-4.75c2.46-1.1,5.31-1.65,8.55-1.65,2.72,0,5.16.39,7.32,1.16,2.16.77,3.97,1.88,5.45,3.31,1.48,1.43,2.57,3.15,3.27,5.17l-6.4,2.32c-.28-1.03-.87-2-1.76-2.92-.89-.91-2.01-1.65-3.38-2.22s-2.89-.84-4.57-.84c-1.74-.05-3.29.22-4.68.81s-2.49,1.42-3.31,2.49c-.82,1.08-1.23,2.37-1.23,3.87,0,1.64.47,2.93,1.41,3.87.94.94,2.19,1.71,3.76,2.32s3.29,1.2,5.17,1.76c1.97.61,3.89,1.27,5.77,1.97,1.87.7,3.58,1.6,5.1,2.67,1.52,1.08,2.75,2.43,3.69,4.04.94,1.62,1.41,3.65,1.41,6.09,0,2.63-.61,5.02-1.83,7.18-1.22,2.16-3.06,3.89-5.52,5.21-2.46,1.31-5.55,1.97-9.25,1.97-3.05,0-5.8-.47-8.23-1.41-2.44-.94-4.44-2.24-6.01-3.9-1.57-1.66-2.62-3.6-3.13-5.8l6.4-2.39Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m392.2,69.94v-9.85h6.68v9.85h8.79v5.91h-8.79v17.3c0,2.16.43,3.68,1.3,4.57.86.89,1.97,1.4,3.3,1.51,1.34.12,2.73.08,4.19-.11v5.7c-1.92.42-3.81.55-5.66.39-1.85-.17-3.52-.69-4.99-1.58s-2.65-2.19-3.52-3.9-1.3-3.91-1.3-6.58v-17.3h-5.06v-5.91h5.06Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m430.47,105.61c-3.33,0-6.32-.74-8.97-2.22-2.65-1.48-4.73-3.56-6.23-6.26-1.5-2.69-2.25-5.87-2.25-9.53s.77-6.92,2.32-9.64c1.55-2.72,3.66-4.82,6.33-6.29s5.67-2.22,9-2.22,6.34.74,9.04,2.22c2.7,1.48,4.82,3.56,6.37,6.26s2.32,5.87,2.32,9.53-.79,6.92-2.36,9.64c-1.57,2.72-3.72,4.82-6.44,6.29-2.72,1.48-5.77,2.22-9.15,2.22Zm-.07-6.05c1.88,0,3.63-.43,5.28-1.3s2.99-2.19,4.04-3.97,1.58-4.06,1.58-6.83-.5-4.96-1.51-6.72-2.33-3.07-3.97-3.94c-1.64-.86-3.4-1.3-5.28-1.3s-3.53.43-5.1,1.3c-1.57.87-2.85,2.2-3.83,3.97-.98,1.78-1.48,4.06-1.48,6.83s.48,4.96,1.44,6.72c.96,1.76,2.22,3.07,3.76,3.94,1.55.87,3.24,1.3,5.06,1.3Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m471.54,105.61c-3.38,0-6.36-.76-8.93-2.29-2.58-1.52-4.58-3.63-6.01-6.3-1.43-2.67-2.15-5.79-2.15-9.35s.73-6.78,2.18-9.5c1.45-2.72,3.47-4.86,6.05-6.4,2.58-1.55,5.58-2.32,9.01-2.32,3.61,0,6.79.85,9.53,2.53,2.74,1.69,4.72,3.97,5.95,6.83l-5.98,2.11c-.7-1.64-1.89-2.95-3.55-3.94-1.66-.99-3.55-1.48-5.66-1.48s-4.01.5-5.56,1.51c-1.55,1.01-2.74,2.42-3.59,4.22-.84,1.8-1.26,3.93-1.26,6.37,0,3.66.94,6.56,2.81,8.72,1.87,2.16,4.41,3.23,7.6,3.23,2.11,0,4-.47,5.66-1.41,1.66-.94,2.92-2.23,3.77-3.87l5.91,2.11c-1.31,2.81-3.38,5.05-6.19,6.72-2.82,1.66-6,2.5-9.57,2.5Z",
          },
        }),
        _c("path", {
          attrs: {
            d: "m495.39,54.47h6.68v30.17h2.89l13.15-14.7h9.01l-16.32,17.59,16.39,17.59h-9.07l-13.15-14.77h-2.89v14.77h-6.68v-50.65Z",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }