
import Vue from 'vue'
export default Vue.extend({
  name: 'OnStockLogoSvg',
  props: {
    color: {
      default: undefined,
      type: String,
    },
  },
})
